<template>
    <div class="container">
        <header class="jumbotron">
            <div class="submit-form">
                <div v-if="submitted">
                    <h4>You submitted successfully!</h4>
                </div>

                <div v-if="!init">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="name"
                            required
                            v-model="device.name"
                            name="name"
                        />
                    </div>

                    <button @click="saveDevice" class="btn btn-success">Submit</button>
                </div>

                <div v-else>
                    <button class="btn btn-success" @click="newDevice">Add</button>(Now by default version 1 with 10 pumps)
                </div>
            </div>
            <strong>Devices:</strong>
            <table class="table table-hover" v-if="devices">
                <thead>
                <tr>
                    <th scope="col">Название</th>
                    <th scope="col">Пользователь</th>
                    <th scope="col">Password</th>
                    <th scope="col">Verified</th>
                    <th scope="col">Version</th>
                    <th scope="col">Owner</th>
                    <th scope="col">Создан</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="device in devices.data" :key="device">
                        <td>
                            <router-link :to="{ name: 'Device', params: { uniqueId: device.unique_id, deviceName: device.name } }" class="device-row">
                                {{device.name}}
                            </router-link>
                        </td>
                        <td>
                            {{device.user.email}}
                        </td>
                        <td>
                            {{device.user_password}}
                        </td>
                        <td>
                            {{device.verified ?? 'No'}}
                        </td>
                        <td>
                            {{device.device_version_device.device_version.name}}
                        </td>
                        <td>
                            <div v-if="device.user_device">
                                {{device.user_device?.user?.name}}
                            </div>
                            <div v-else>
                                <select v-model="selectedUser" @change="onSelectChange(device.unique_id, selectedUser.unique_id )">
                                    <option v-for="user in users.data" :key="user" :value="user">
                                        {{ user.name }}
                                    </option>
                                </select>
                            </div>
                        </td>
                        <td>
                            {{device.created_at}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </header>
    </div>
</template>

<script>
import DevicesService from "../services/devices.service";
import UserService from "@/services/user.service";

export default {
    name: "Device",
    data() {
        return {
            selectedUser: null,
            devices: null,
            users: null,
            device: {
                id: null,
                unique_id: "",
                name: "",
                user_device: {
                    user: {
                        name: "",
                    },
                },
                device_version_device: {
                    device_version: {
                        name: "",
                    }
                },
                user: {
                    email: "",
                },
                user_password: "",
                user_id: "",
                verified: "",
                type: "",
                created_at: "",
            },
            submitted: false,
            init: true,
        };
    },
    methods: {
        saveDevice() {
            const data = {
                name: this.device.name,
            };

            DevicesService.create(data)
                .then(response => {
                    this.device.id = response.data.id;
                    this.submitted = true;
                    this.init = true;
                    this.getDevicesBoard();
                })
                .catch(e => {
                    console.log(e);
                });
        },

        newDevice() {
            this.submitted = false;
            this.init = false;
            this.device = {};
        },

        getDevicesBoard() {
            DevicesService.getDevicesBoard().then(
                (response) => {
                    this.getUsersBoard();
                    this.devices = response.data;
                },
                (error) => {
                    this.devices =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUsersBoard() {
            UserService.getUsersBoard('bar').then(
                (response) => {
                    this.users = response.data;
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        attachUser(device_id, user_id) {
            let data = {
                'user_id': user_id,
            }

            DevicesService.attachUser(device_id, data).then(
                (response) => {
                    this.users = response.data;
                    this.getDevicesBoard();
                },
                (error) => {
                    this.users =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        onSelectChange(device_id, user_id) {
            this.attachUser(device_id, user_id);
        }
    },
    mounted() {
        this.getDevicesBoard();
    },
};
</script>

<style scoped>
    .device-row {
        cursor: pointer;
    }
</style>
